<template>
  <div v-if="detail">
    <DetailComponent
      :coReqID="coReqID"
      :dataCo="dataCo"
      @reloadData="handleChangePage(table.page)"
      @loading="handleLoading"
      @handleClose="handleClose"
    />
  </div>
  <vx-card v-else :title="title">
    <!-- New Layout header -->
    <div class="vx-row">
      <div class="vx-col w-1/2">
        <label class="vs-input--label">Territory</label>
        <multiselect
          class="selectExample"
          v-model="selectedTerritory"
          :disabled="!isFilterTerr"
          :options="optionTerritory"
          :multiple="false"
          :allow-empty="false"
          :group-select="false"
          :max-height="100"
          :limit="3"
          placeholder="Type to search"
          :searchable="true"
          :custom-label="customLableTerritory"
        >
        </multiselect>
      </div>
      <div class="vx-col w-1/2">
        <label class="vs-input--label">Salesman</label>
        <multiselect
          class="selectExample"
          v-model="selectedPersonals"
          :options="optionalPersonals"
          :show-labels="false"
          :multiple="false"
          :allow-empty="false"
          :group-select="false"
          :max-height="160"
          placeholder=" Type to search"
          :custom-label="customLablePersonals"
        >
        </multiselect>
      </div>
      <div class="vx-col w-1/2">
        <label class="vs-input--label">Date Start</label>
          <datepicker
              name="date_start"
              format="yyyy-MM-dd"
              :inline="false"
              v-model="dateStart"
              placeholder="Select Date"
              :disabledDates="{
                from: dateEnd,
              }"
            ></datepicker>
        <!-- <vs-input class="w-full" type="date" v-model="dateStart" :max="dateEnd" /> -->
      </div>
      <div class="vx-col w-1/2">
        <label class="vs-input--label">Date End</label>
        <datepicker
              name="date_end"
              format="yyyy-MM-dd"
              :inline="false"
              v-model="dateEnd"
              placeholder="Select Date"
              :disabledDates="{
                to: dateStart
              }"
            ></datepicker>
        <!-- <vs-input class="w-full" type="date" v-model="dateEnd" :min="dateStart" /> -->
      </div>
    </div>
    
    <div class="vx-row ml-1 mb-6">
      <div class="vx-col sm:w-1/4 w-full"> &nbsp; </div>
      <div class="vx-col w-3/4 w-full">
        <div class="vx-row">
          <vs-button 
          class="m-2" 
          type="border" 
          color="primary" 
          @click="btnSearchFilter()"
          icon-pack="feather"
          icon="icon-filter"
          >Search</vs-button
          >
          <vs-button
            class="m-2"
            color="primary"
            type="border"
            icon-pack="feather"
            icon="icon-check"
            @click="handleExec()"
            >Execute</vs-button
          >
        </div>
      </div>
    </div>

    

    <div class="vx-row mb-12">
      <vs-tabs>
        <vs-tab label="New" @click="tabClick(tabNew)">
          <div class="con-tab-ejemplo">
            <newTab
              :tblServer="table"
              :checkAll="checkAll"
              @tblSearch="handleSearch"
              @handleDetail="handleDetail"
              @tblChangePage="handleChangePage"
              @onInputCheck="handleCheck"
              @tblSort="handleSort"
              @tblChangeLength="handleChangelength"
            />
          </div>
        </vs-tab>
        <vs-tab label="Sales Order" @click="tabClick(tabSO)">
          <div class="con-tab-ejemplo">
            <soTab
              :tblServer="table"
              @tblSearch="handleSearch"
              @handleDetail="handleDetail"
              @tblChangePage="handleChangePage"
              @tblSort="handleSort"
              @tblChangeLength="handleChangelength"
            />
          </div>
        </vs-tab>
        <vs-tab label="Delivery Note" @click="tabClick(tabDelivNote)">
          <div class="con-tab-ejemplo">
            <delivNote
              :tblServer="table"
              @tblSearch="handleSearch"
              @handleDetail="handleDetail"
              @tblChangePage="handleChangePage"
              @tblSort="handleSort"
              @tblChangeLength="handleChangelength"
            />
          </div>
        </vs-tab>
        <vs-tab label="POD / GI" @click="tabClick(tabPodGi)">
          <div class="con-tab-ejemplo">
            <podGi
              :tblServer="table"
              @tblSearch="handleSearch"
              @handleDetail="handleDetail"
              @tblChangePage="handleChangePage"
              @tblSort="handleSort"
              @tblChangeLength="handleChangelength"
            />
          </div>
        </vs-tab>
        <vs-tab label="Billing" @click="tabClick(tabBilling)">
          <div class="con-tab-ejemplo">
            <billing
              :tblServer="table"
              @tblSearch="handleSearch"
              @handleDetail="handleDetail"
              @tblChangePage="handleChangePage"
              @tblSort="handleSort"
              @tblChangeLength="handleChangelength"
            />
          </div>
        </vs-tab>
        <vs-tab label="Collection" @click="tabClick(tabCollection)">
          <div class="con-tab-ejemplo">
            <collection
              :tblServer="table"
              @tblSearch="handleSearch"
              @handleDetail="handleDetail"
              @tblChangePage="handleChangePage"
              @tblSort="handleSort"
              @tblChangeLength="handleChangelength"
            />
          </div>
        </vs-tab>
      </vs-tabs>
    </div>
  </vx-card>
</template>
<script>

import DetailComponent from "./view.vue";
import Datepicker from "vuejs-datepicker";
import newTab from "./newTab.vue";
import soTab from "./soTab.vue";
import podGi from "./podGi.vue";
import billing from "./billing.vue";
import delivNote from "./delivNoteTab.vue";
import collection from "./collection.vue";
import moment from "moment";
// import Territory from "../../components/master/Territory.vue";

export default {
  components: {
    DetailComponent,
    newTab,
    soTab,
    podGi,
    billing,
    delivNote,
    collection,
    Datepicker,
    // Territory,
  },
  watch: {
    selectedTerritory() {
      this.getPersonal();
    },
  },
  data() {
    return {
      title: "Manage Canvas Sales",
      baseUrl: "/api/sfa/v1/customer-order/canvas",
      detailShow: "core vx-col md:w-1/2 w-full mb-base ",
      detailHide: "core vx-col md:w-1/1 w-full mb-base ",
      deleteId: null,
      id: null,
      coReqID: 0,
      tabActive: 0,
      tabNew: 0,
      tabSO: 1,
      tabDelivNote: 2,
      tabPodGi: 3,
      tabBilling: 4,
      tabCollection:5,
      dataCo: {},
      delivNote: 4,
      tabExport: 5,
      dataCoreqIds: [],
      coID: 0,
      checkAll: false,
      dataReload: true,
      detail: false,
      isFilterTerr: true,
      table: this.tableDefaultState(),
      tableDetails: {},
      selectedRow: null,

      selectedPersonals: [{ personal_name: "", id: 0 }],
      optionalPersonals: [{ personal_name: "", id: 0 }],
      allPersonals: false,
      oldSalesmanData: [],
      optionTerritory: [],
      allTerritory: false,
      selectedTerritory: null,
      dateStart: new Date(),
      dateEnd: new Date(),
      copied: null,
      activePrompt: false,
      selectedXml: null,
      modalHeight: 300,
      modalWeight: 100,
      tabType: "",
      territorryId: null,
      territoryCode: null,
    };
  },
  mounted() {
    this.getTerritory();
    // this.getData({ type: "new" });
  },
  methods: {
    setTerritoryId(id, code) {
      this.selectedTerritory = {
        id: id,
        code: code,
      };
      this.territorryId = id;
      this.territoryCode = code;
    },
    tableDefaultState() {
      return {
        data: [],
        length: 10,
        page: 1,
        search: "",
        order: "id",
        sort: "desc",
        total: 0,
        totalpage: 1,
        totalSearch: 0,
        limits: [10, 25, 50, 100, "All"],
        start: 1,
        end: 0,
      };
    },
    tabClick(tab) {
      this.tabActive = tab;
      if (tab == this.tabNew) {
        this.getData({ type: "new" });
      } else if (tab == this.tabSO) {
        this.getData({ type: "sales_order" });
      } else if (tab == this.tabDelivNote) {
        this.getDataCVOms({ canvas_tab_type: "delivery_note" }, 300);
      } else if (tab == this.tabPodGi) {
        this.getDataCVOms({ canvas_tab_type: "pod_gi" }, 302);
      } else if (tab == this.tabBilling) {
        this.getDataCVOms({ canvas_tab_type: "billing" }, 304);
      } else if (tab == this.tabCollection){
        this.getData({ type: "collection" }, 306);        
      }
    },
    handleSearch(searching) {
      this.table.search = searching;
      this.table.page = 1;
      if (this.tabActive < 2){
        this.getData({ type: this.tabType });
      } else if (this.tabActive == this.tabDelivNote) {
        this.getDataCVOms({ canvas_tab_type: "delivery_note" }, 315);
      } else if (this.tabActive == this.tabPodGi) {
        this.getDataCVOms({ canvas_tab_type: "pod_gi" }, 317);
      } else if (this.tabActive == this.tabBilling) {
        this.getDataCVOms({ canvas_tab_type: "billing", is_billed:1 }, 319);
      } else if (this.tabActive == this.tabCollection){
        this.getData({ type: "collection" }, 321);        
      }
    },
    handleExec() {
      console.log(this.table.data.length);
      console.log("this.dataCoreqIds", this.dataCoreqIds);
      if (this.dataCoreqIds.length == 0) {
        this.$vs.notify({
          color: "warning",
          title: "Warning",
          text: "Please Select Data First",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-alert-circle",
        });
      } else {
        this.$vs.loading();
        let endpoint_url = "/api/sfa/v1/customer-order/create-by-co-req";
        this.$http
          .post(endpoint_url, {customer_order_request_ids: this.dataCoreqIds})
          .then((resp) => {
            if (resp.code == 200) {
              this.$vs.notify({
                color: "success",
                title: "Success",
                text: resp.message,
                position: "top-right",
                iconPack: "feather",
                icon: "icon-check-circle",
              });
              this.dataCoreqIds = [];
              this.$vs.loading.close();
              if (this.tabActive < 2){
                this.getData({ type: this.tabType });
              } else if (this.tabActive == this.tabDelivNote) {
                this.getDataCVOms({ canvas_tab_type: "delivery_note" }, 315);
              } else if (this.tabActive == this.tabPodGi) {
                this.getDataCVOms({ canvas_tab_type: "pod_gi" }, 317);
              } else if (this.tabActive == this.tabBilling) {
                this.getDataCVOms({ canvas_tab_type: "billing", is_billed:1 }, 319);
              } else if (this.tabActive == this.tabCollection){
                this.getData({ type: "collection" }, 321);        
              }
            } else {
              this.$vs.notify({
                color: "danger",
                title: "Error",
                text: resp.message,
                position: "top-right",
                iconPack: "feather",
                icon: "icon-x-circle",
              });
              this.$vs.loading.close();
            }
          });
      }
    },
    handleChangePage(page) {
      this.table.page = page;
      if (this.dataReload) {
        if (this.tabActive < 2){
          this.getData({ type: this.tabType });
        } else if (this.tabActive == this.tabDelivNote) {
          this.getDataCVOms({ canvas_tab_type: "delivery_note" }, 371);
        } else if (this.tabActive == this.tabPodGi) {
          this.getDataCVOms({ canvas_tab_type: "pod_gi" });
        } else if (this.tabActive == this.tabBilling) {
          this.getDataCVOms({ canvas_tab_type: "billing", is_billed:1 }, 375);
        } else if (this.tabActive == this.tabCollection){
          this.getData({ type: "collection" }, 377);        
        }
      }
      this.dataReload = true;
    },
    handleDetail(co) {
      this.coReqID = co.id;
      this.dataCo = co;
      this.detail = true;
    },
    handleSort(key, active) {
      this.table.order = key;
      this.table.sort = active;
      // this.getData({ type: this.tabType });
      if (this.tabActive < 2){
        this.getData({ type: this.tabType });
      } else if (this.tabActive == this.tabDelivNote) {
        this.getDataCVOms({ canvas_tab_type: "delivery_note" }, 394);
      } else if (this.tabActive == this.tabPodGi) {
        this.getDataCVOms({ canvas_tab_type: "pod_gi" }, 396);
      } else if (this.tabActive == this.tabBilling) {
        this.getDataCVOms({ canvas_tab_type: "billing", is_billed:1 }, 398);
      } else if (this.tabActive == this.tabCollection){
        this.getData({ type: "collection" }, 400);        
      }
    },
    handleChangelength(val) {
      this.table.length = val == "All" ? this.table.total : val;
      this.table.page = 1;
      // this.getData({ type: this.tabType });
      if (this.tabActive < 2){
        this.getData({ type: this.tabType });
      } else if (this.tabActive == this.tabDelivNote) {
        this.getDataCVOms({ canvas_tab_type: "delivery_note" }, 410);
      } else if (this.tabActive == this.tabPodGi) {
        this.getDataCVOms({ canvas_tab_type: "pod_gi"}, 412);
      } else if (this.tabActive == this.tabBilling) {
        this.getDataCVOms({ canvas_tab_type: "billing", is_billed:1 }, 414);
      } else if (this.tabActive == this.tabCollection){
        this.getData({ type: "collection" }, 416);        
      }
    },
    handleClose() {
      // this.getData({ type: this.tabType });
      this.detail = false;
      this.dataReload = false;
    },
    handleCreate() {
      this.$router.push({
        // name: "customer-order-create_v1",
        name: "customer-order-create",
      });
    },
    handleCheck(i) {
      if (i == -1) {
        if (!this.checkAll) {
          this.dataCoreqIds = [];
          this.table.data.forEach((el, ii) => {
            this.table.data[ii].check = true;
            this.dataCoreqIds.push(el.id);
          });
          this.checkAll = true;
        } else {
          this.table.data.forEach((el, ii) => {
            this.table.data[ii].check = false;
          });
          this.dataCoreqIds = [];
          this.checkAll = false;
        }
      } else {
        this.table.data[i].check = !this.table.data[i].check;
        if (this.table.data[i].check) {
          this.dataCoreqIds.push(this.table.data[i].id);
        } else {
          this.checkAll = false;
          this.dataCoreqIds = this.dataCoreqIds.filter(
            (v) => v != this.table.data[i].id
          );
        }
      }
      console.log(this.dataCoreqIds);
    },
    getData(params) {
      var terrID = 0;
      var persID = 0;
      if (params && params.type) {
        this.tabType = params.type;
      }else{
        params.type = "new";
      }
      if (this.selectedTerritory) {
        terrID = this.selectedTerritory.id;
      } else {
        this.allTerritory = true;
      }
      if (this.selectedPersonals.id != undefined) {
        persID = this.selectedPersonals.id;
      } else {
        this.allPersonals = true;
      }
      this.$vs.loading();
      let paramTable = {
        length: this.table.length,
        page: this.table.page,
        search: this.table.search,
        order: this.table.order,
        sort: this.table.sort,
        date_start: this.dateStart,
        date_end: this.dateEnd,
        territory_id: terrID,
        personal_id: persID,
        allTerritory: this.allTerritory,
        allPersonals: this.allPersonals,
        data: [],
      };
      this.$http
        .get(this.baseUrl, {
          params: {
            ...paramTable,
            ...params,
          },
        })
        .then((resp) => {
          console.log(resp);
          if (resp.code == 200) {
            this.table.data = [];
            this.table.total = resp.data.total_record;
            this.table.totalPage = resp.data.total_page;
            this.table.totalSearch = resp.data.total_record_search;
            this.table.length = resp.data.total_record_per_page;

            if (resp.data.records) {
              let data = {};
              let datas = [];
              resp.data.records.forEach((el) => {
                data = el;
                data.check = this.checkAll;
                datas.push(data);
              });
              this.table.data = resp.data.records;
            } else {
              this.table.data = [];
            }
            this.setStartEnd();
            this.$vs.loading.close();
            console.log(resp.data);
          } else {
            console.log(resp.data);
          }
          console.log(resp.data);
        });
    },
    getDataCVOms(params, from) {
      console.log(params);
      console.log("getDataCVOms");
      console.log("from", from);
      let url = "/api/oms/v1/sfa-sales-order/sales-order-mix2";
      let terr_code = this.selectedTerritory.code!="" ? this.selectedTerritory.code : "All";

      let paramTable = {
        limit: this.table.length,
        offset: 0,
        search: this.table.search,
        so_types: ['ZDCV'],
        territory_code: terr_code,
        start_date: this.dateStart,
        end_date: this.dateEnd,
        data: [],
        sales_personal_id: this.selectedPersonals.id != undefined ? this.selectedPersonals.id : null,
        is_use_approval_matrix: false,
        progress_restriction: true,
        progress_status: 'allocated',
      };
      params = {
        ...paramTable,
        ...params
      }

      console.log("params", params);
      console.log("url", url);
      
      this.$vs.loading();
      this.$http.get(url, { params: params, }) .then((resp) => {
        console.log(resp);
        if (resp.code == 200) {
          this.table.data = [];
          this.table.total = resp.data.RecordsTotal;
          this.table.totalPage = resp.data.RecordsTotal / this.table.length;
          this.table.totalSearch = resp.data.ResultsTotal;
          if (resp.data.SalesOrders) {
            this.table.data = resp.data.SalesOrders;
          } else {
            this.table.data = [];
          }
          this.setStartEnd();
          this.$vs.loading.close();
          console.log(resp.data);
        } else {
          console.log(resp.data);
        }
        console.log(resp.data);
      });
    },
    setStartEnd() {
      let valStart = this.table.length * this.table.page - this.table.length + 1;
      if (valStart > this.table.total) {
        valStart = 1;
      }
      if (this.table.total == 0) {
        valStart = 0;
      }
      let valEnd = this.table.length * this.table.page;
      if (valEnd > this.table.total) {
        valEnd = this.table.total;
      }
      if (this.table.totalSearch < this.table.total) {
        valEnd = this.table.totalSearch;
      }

      this.table.start = valStart;
      this.table.end = valEnd;
    },
    btnSearchFilter() {
      console.log(">>>>", this.selectedPersonals, this.selectedPersonals.id == 0);

      if (this.selectedTerritory.id == 0) {
        this.allTerritory = true;
      } else {
        this.allTerritory = false;
      }
      if (this.selectedPersonals.id == undefined || this.selectedPersonals.id == 0) {
        this.allPersonals = true;
      } else {
        this.allPersonals = false;
      }

      let params = {
        date_start: this.dateStart,
        date_end: this.dateEnd,
        territory_id: this.selectedTerritory.id,
        personal_id: this.selectedPersonals.id,
        allTerritory: this.allTerritory,
        allPersonals: this.allPersonals,
        type: this.tabType,
      };

      if (this.tabActive < 2){
        this.getData(params);
      } else if (this.tabActive == this.tabDelivNote) {
        this.getDataCVOms({ canvas_tab_type: "delivery_note" }, 626);
      } else if (this.tabActive == this.tabPodGi) {
        this.getDataCVOms({ canvas_tab_type: "pod_gi" }, 628);
      } else if (this.tabActive == this.tabBilling) {
        this.getDataCVOms({ canvas_tab_type: "billing", is_billed:1 }, 630);
      } else if (this.tabActive == this.tabCollection){
        this.getData({ type: "collection" }, 632);
      }
    },
    getPersonal() {
      if (this.selectedTerritory.id) {
        this.$vs.loading();
        this.$http
          .get("/api/sfa/v1/salesman-items/personal", {
            params: {
              length: 0,
              territory_id: this.selectedTerritory.id,
            },
          })
          .then((resp) => {
            if (resp.code == 200) {
              if (resp.data.records) {
                console.log(">>>>>", resp);
                this.optionalPersonals = [{ personals_name: "All", id: 0 }];
                for (let index = 0; index < resp.data.records.length; index++) {
                  this.optionalPersonals.push(resp.data.records[index]);
                }
                if (this.optionalPersonals.length > 0) {
                  this.selectedPersonals = this.optionalPersonals[0];
                } else {
                  this.optionalPersonals = [];
                  this.selectedPersonals = {};
                }
              } else {
                this.optionalPersonals = [];
                this.selectedPersonals = {};
              }

              this.$vs.loading.close();
            } else {
              this.$vs.loading.close();
            }
          });
      } else {
        this.optionalPersonals = [];
        this.selectedPersonals = [{ personals_name: "", id: "" }];
      }
    },
    handleLoading(is_loading) {
      if (is_loading) {
        this.$vs.loading();
      } else {
        this.$vs.loading.close();
      }
    },
    getTerritory() {
      this.$vs.loading();
      this.$http
        .get("/api/sfa/v1/customer-order/territory", {
          params: {
            length: 0,
            order: "name",
            sort: "asc",
          },
        })
        .then((resp) => {
          if (resp.code == 200) {
            if (resp.data.records) {
              if (resp.data.records.length <= 1) {
                this.isFilterTerr = false;
              } else {
                this.optionTerritory = [{ code: "", name: "All", id: 0 }];
              }
              for (let index = 0; index < resp.data.records.length; index++) {
                this.optionTerritory.push(resp.data.records[index]);
              }
              console.log("this.optionTerritory", this.optionTerritory);
              // this.optionTerritory = resp.data.records;
              if (this.optionTerritory.length > 0) {
                this.selectedTerritory = this.optionTerritory[0];
              } else {
                this.optionTerritory = [];
                this.selectedTerritory = {};
              }
            } else {
              this.optionTerritory = [];
              this.selectedTerritory = {};
            }

            this.$vs.loading.close();
          } else {
            this.$vs.loading.close();
          }
        });
    },
    customLableTerritory({ code, name }) {
      return `${code} ${name}`;
    },
    customLablePersonals({ personals_name }) {
      console.log("personals_name", personals_name);
      if (personals_name == "") {
        return `All`;
      } else {
        return `${personals_name}`;
      }
    },
    copyData(data) {
      const el = document.createElement("textarea");
      el.value = data;
      document.body.appendChild(el);
      el.select();
      document.execCommand("copy");
      document.body.removeChild(el);
      this.copied = data;
    },
    openConfirm(data) {
      this.activePrompt = true;
      this.selectedRow = data;
    },
  },

  filters: {
    formatDate(value) {
      if (value) {
        let dateFmt = moment(String(value));
        if (dateFmt.year() == "0001") {
          dateFmt = "-";
        } else {
          dateFmt = dateFmt.format("DD MMM YYYY");
        }
        return dateFmt;
      }
    },
    formatStatus(value) {
      if (value == 1) {
        return "Open";
      } else {
        return "Close";
      }
    },
    formatCurrency(value) {
      if (value) {
        return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }
    },
    dataToShow(value) {
      if (value.length < 10) {
        return `${value.slice(0, 9)}`;
      } else {
        return `${value.slice(0, 5)}...${value.slice(-5)}`;
      }
    },
    formatXml(xmlString) {
      if (!xmlString) {
        return "";
      }

      const parser = new DOMParser();
      const xmlDoc = parser.parseFromString(xmlString, "application/xml");

      if (xmlDoc.documentElement.nodeName === "parsererror") {
        return "";
      }

      const serializer = new XMLSerializer();
      let xml = serializer.serializeToString(xmlDoc);

      // const indentSize = 2;
      const indentCharacter = " ";

      const formatXmlRecursive = (node, level) => {
        let indent = new Array(level + 1).join(indentCharacter);
        let formattedXml = "";

        if (node.hasChildNodes()) {
          let currentNode = node.firstChild;

          while (currentNode) {
            if (currentNode.nodeType === Node.ELEMENT_NODE) {
              formattedXml += "\n" + indent + "<" + currentNode.nodeName + ">";

              let hasChildElements =
                currentNode.hasChildNodes() &&
                Array.from(currentNode.childNodes).some(
                  (childNode) => childNode.nodeType === Node.ELEMENT_NODE
                );

              if (hasChildElements) {
                formattedXml += formatXmlRecursive(currentNode, level + 1);
                formattedXml += "\n" + indent;
              }

              formattedXml += "</" + currentNode.nodeName + ">";
            } else if (currentNode.nodeType === Node.TEXT_NODE) {
              let trimmedText = currentNode.textContent.trim();

              if (trimmedText !== "") {
                formattedXml += trimmedText;
              }
            }

            currentNode = currentNode.nextSibling;
          }
        }

        return formattedXml;
      };

      xml = formatXmlRecursive(xmlDoc, 0);
      return xml.trim();
    },
  },
  computed: {
    setPage: {
      get() {
        return 1;
      },
      set(val) {
        this.handleChangePage(val);
      },
    },
  },
};
</script>

<style scoped>
.core-enter-active {
  transition: all 0.3s ease;
}
.core-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}
.core-enter, .core-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}
.core {
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -o-transition: all 1s ease;
  transition: all 1s ease;
}
.vs-con-table .vs-con-tbody .vs-table--tbody-table {
  font-size: 12px;
}
.vertical-divider {
  border-left: 1px solid #7367f0;
  min-height: 800px;
  padding: 5px;
}

.modal-content {
  padding: 0;
  margin: 0;
  width: 100%;
  background-color: rgb(243, 237, 237);
  overflow-y: auto;
  border-radius: 5px;
}
.modal-bg {
  position: fixed;
  max-width: 100%;
  size: 100%;
  top: 15%;
  left: 0;
  right: 0;
  bottom: 0;
  /* backdrop-filter: blur(5px); efek blur */
  z-index: 999;
}
.con-vs-dialog .vs-dialog {
  max-width: 500px !important;
}
</style>
